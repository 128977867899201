<template>
    <div>
        <title-bar
            :submitBtn="$helper.userCapability('create',$route)"
            :secondaryBtn="$helper.userCapability('create',$route)"
            :submitBtnTitle="$t('forms.addDirectory')"
            :secondaryBtnTitle="$t('forms.addDocument')"
            :title="$t('navigations.documents')"
            @submitPressed="submitPressed"
            @secondaryPressed="secondaryPressed"
        />
        <b-card
            :header="$t('forms.root')"
            header-tag="strong"
        >
            <directory-list :directory="directory" :documents="this.documents" :directories="this.directories"
                            :action="action"
                            @deleteDirectory="deleteDirectory"
                            @deleteDocument="deleteDocument"
                            @clearAction="clearAction"
                            @clearActionOnly="clearActionOnly"
                            @editDocument = "editDocument"
                            @editDirectory = "editDirectory"
            />
        </b-card>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import DirectoryList from "@/components/Files/DirectoryList";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
    name: "Documents",
    components: {
        TitleBar,
        DirectoryList,
    },
    mixins: [ResourceUtils],
    data() {
        return {
            directory: null,
            directories: [],
            documents: [],
            action: null
        };
    },
    created() {
        this.loadDirectories();
        this.loadDocuments();
    },
    computed: {},
    methods: {
        loadDirectories() {
            this.$Directories.getCollection({
                params: {
                    'exists[parent]': false,
                    'exists[customer]': false,
                    'order[position]': 'ASC',
                    'order[name]': 'ASC',
                    'pagination': false
                }
            }, "directory_list").then((response) => {
                this.directories = response.data["hydra:member"];
            });
        },
        loadDocuments() {
            this.$Documents.getCollection({
                params: {
                    'exists[directory]': false,
                    'exists[customer]': false,
                    'order[position]': 'ASC',
                    'order[name]': 'ASC',
                    'pagination': false
                }
            }).then((response) => {
                this.documents = response.data["hydra:member"];
            });
        },
        deleteDirectory(url) {
            this.deleteByUrl(this.$Files, url, this.$t("messages.directoryDeleted"), null, this.loadDirectories, null);
        },
        deleteDocument(url) {
            this.deleteByUrl(this.$Files, url, this.$t("messages.documentDeleted"), null, this.loadDocuments, null);
        },
        editDocument() {
            this.action = 'editDocument'
        },
        editDirectory() {
            this.action = 'editDirectory'
        },
        submitPressed() {
            this.action = 'addDirectory'
        },
        secondaryPressed() {
            this.action = 'addDocument'
        },
        clearAction() {
            this.loadDirectories()
            this.loadDocuments()
            this.action = null
        },
        clearActionOnly() {
            this.action = null
        }
    },
};
</script>
